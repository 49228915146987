import React from 'react'

const BubleImg = () => {
  return (
    <div className='aspect-square bg-profile border-[#393939] border-4 h-[15rem] lg:h-[25rem]'>
    </div>
  )
}

export default BubleImg
